<template>
  <v-container>
    {{ t('message.hello') }}
  </v-container>
</template>

<script setup>
// import DropdownInput from '@/components/shared/customComponents/inputs/dropdown-input.vue';
// import RegularTextInput from '@/components/shared/customComponents/inputs/regular-text-input.vue';
import request from '@/helpers/httpClient';
//import { useI18n } from 'vue-i18n-bridge';
import { ref, defineProps, onBeforeUnmount } from 'vue';
// import languages from '../../components/profile/locales/languages';

// --- props ---
const props = defineProps({
  currentProfileId: {
    type: String,
    default: null,
    required: false,
  },
});

// const { t } = useI18n({ locale: 'en', messages: languages });
//const { t, locale } = useI18n();

// --- Variables ---
// const isDisabled = ref(true);
const bankDetails = ref({
  payee: null,
  paymentMethod: null,
  iban: null,
  bic: null,
  bankCode: null,
});

// --- Methods ---
const getProfileBankInformation = async () => {
  const id = props.currentProfileId || 'fae285b0-362f-44b1-ae21-a94a24ad40a8';

  const params = {
    endpoint: `v2/api/employee/${id}/bank`,
    configs: {
      method: 'GET',
    },
  };

  try {
    const data = await request.service(params);
    Promise.resolve(data);
    bankDetails.value = data;
  } catch (error) {
    return Promise.reject(error);
  }
};

// const toggleDisabled = () => {
//   isDisabled.value = !isDisabled.value;
// };

// --- Lifecycle hooks ---

// --- Created ---
getProfileBankInformation();

// --- Before destroy ---
onBeforeUnmount(() => {});

// --- Computed ---

// const btnBankDetailsName = computed(() => {
//   const t = this.t.bind(this);
//   return !this.isDisabled
//     ? t('Action_.buttons.saveChanges')
//     : t('Action_.buttons.editInformation');
// });

// const paymentMethods = computed(() => {
//   const t = this.t.bind(this);
//   const methods = this.$store.state.profile.profileResources.paymentTypes;

//   methods.map((object) => {
//     object.text = t('Action_.selectPaymentType')[object.Value];
//     object.value = object.Value;
//   });
//   return methods;
// });

// const isPostFormInProgress = computed(() => {
//   return this.$store.state.isPostFormInProgress;
// });
</script>
